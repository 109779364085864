<div id="hauptcontent">
  <mat-card class="bestelltag-kopf">
    <strong>Menü</strong>
    <strong>Beschreibung</strong>
    <strong>Menge</strong>
    <strong *ngIf="preis_anzeige">Einzelpreis</strong>
    <strong *ngIf="preis_anzeige">Summe</strong>
  </mat-card>
  <ng-container *ngIf="!bestellungLeer">
    <div id="bestellhistorie">
      <table *ngFor="let tag of bestellung.tage | keyvalue">
        <tr class="bestelltag">
          <div class="bestelltag-menues">
            <strong>{{ tag.key | datum }}</strong>
            <ng-container  *ngFor="let menu of tag.value.bestellMenues | speiseplanTagesmenus:bestellung.tage[tag.key].bestellMenues; index as zeile">
              <div [class.lonely]="zeile == 0" class="bestellungtag-zeile" *ngIf="menu.mengeNeu || menu.mengeAlt != 0">
                <div class="bestellungtag-zeile-content">
                  <td>{{ bestellung.menutexte[menu.menueTextId].bezeichnung }}</td>
                  <td [innerHTML]="bestellung.menutexte[menu.menueTextId].menueText | bbCode"></td>
                  <td>{{ menu.mengeAlt }}</td>
                  <td *ngIf="preis_anzeige">{{ menu.einzelPreis | euroPrice}}</td>
                  <td *ngIf="preis_anzeige">{{ menu.gesamtBetrag | euroPrice }}</td>
                </div>
                <span class="menu-fehler" *ngIf="menu.error">{{ menu.error.msgId | speiseplan_fehler }}</span>
              </div>
            </ng-container>
          </div>
        </tr>
      </table>
    </div>
  </ng-container>
  <ng-container  *ngIf="bestellungLeer">
    <div id="bestellhistorieLeer">
      <mat-icon>event_busy</mat-icon>
    </div>
  </ng-container>
</div>
