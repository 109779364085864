<div id="faqContainer" *ngIf="dataSource">
  <mat-form-field>
    <div class="suche">
      <mat-icon>search</mat-icon>
      <input matInput (keyup)="applyFilter($event)" #input id="inputFilter">
      <mat-icon *ngIf="dataSource.filter != ''" (click)="clearFilter(); accordion.closeAll()">close</mat-icon>
    </div>
  </mat-form-field>
  <table
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8 faq-table"
    [class.tableWba]="detectedPlattform != 'WEB'"
  >
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>FAQ Themen</th>
        <ng-container *matCellDef="let element">
          <!--hier kann in das mat expansion panel ein ngif rein sobald das ticket mit dem status aktiviert wird-->
          <mat-expansion-panel mat-cell [class.panelWba]="detectedPlattform != 'WEB'">
            <mat-expansion-panel-header>
              <mat-panel-title [innerHTML]="'<strong>' + element.name + '</strong>'" class="faqTitel">
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p *ngIf="element.name != 'Installationsanleitung'" [innerHTML]="element.content | bbCode | img | trustHTML | async"></p>
            <div *ngIf="element.name == 'Installationsanleitung' && environment.type == 'mobil'">
              <app-pwa-install></app-pwa-install>
            </div>
          </mat-expansion-panel>
        </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Kein Thema zum Suchbegriff: "{{ input.value }}" gefunden.</td>
    </tr>
  </table>
</div>
