import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'speiseplanTagesmenus'
})
export class SpeiseplanTagesmenuPipe implements PipeTransform {

  transform(menus: any, menuTexte: any): any {
    console.log(menus, menuTexte)
    let sortierteTagesMenues = []
    Object.values(menus).forEach((menu:any) => {
      if (!menu['menueNr']){
        menu['menueNr'] = menuTexte[menu.menueTextId].menueNr
      }
      sortierteTagesMenues.push(menu)
    });
    sortierteTagesMenues.sort((a,b) => (a.menueNr > b.menueNr) ? 1 : ((b.menueNr > a.menueNr) ? -1 : 0))
    console.log(sortierteTagesMenues)
    return sortierteTagesMenues
  }
}
