import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {Platform} from '@angular/cdk/platform';
import {environment} from 'environments/environment';
import {ApiCallService} from '../../../../services/api/api-call.service';

@Component({
  selector: 'speiseplanMenuBeschreibung',
  templateUrl: './speiseplanMenuBeschreibung.component.html',
  styleUrls: ['./speiseplanMenuBeschreibung.component.css'],
})
export class SpeiseplanMenuBeschreibungComponent implements OnInit {

  @Input('menu') menu
  @Input('mandant') mandant
  public menuAIZ
  public detectedPlattform: string
  public menuZusatz


  constructor(
    public dialog: MatDialog,
    public platform: Platform,
    public apiCallService: ApiCallService,
  ) {}

  ngOnInit() {
    this.detectedPlattform = environment.type
    if (this.menu.allergene && this.menu.inhaltsstoffe && this.menu.zusatzstoffe){
      this.menuAIZ = [...this.menu.allergene, ...this.menu.inhaltsstoffe, ...this.menu.zusatzstoffe];
    }
    if (this.menu.allergeneIds && this.menu.inhaltsstoffeIds && this.menu.zusatzstoffeIds){
      this.menuAIZ = [...this.menu.allergeneIds, ...this.menu.inhaltsstoffeIds, ...this.menu.zusatzstoffeIds];
    }
    if (this.menu.menueText.includes('*')){
      this.menuZusatzContent()
    }
  }

  menuZusatzContent() {
    let command = this.detectedPlattform == 'web' ? 'Web_Menuetextergaenzung' : 'App_Menuetextergaenzung'
    this.apiCallService.request('/public', 'content/get', {
      mandantId: environment.mandant_id ,
      'contentItemIds': JSON.stringify([]),
      zweck: command,
      lang: 'de'
    }).subscribe((result:any) => {
      this.menuZusatz = atob(result[0].content)
      let elements = document.querySelectorAll('.speiseplan-menu-wbp');
      elements.forEach((element:any) => {
        element.style.height = '145px';
      })
    })
  }

  protected readonly environment = environment;
}
